<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateState">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="state.name" class="form-control" id="name" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/states";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      state: {
        name: "",
      },
      stateId: this.$route.params.id,
    };
  },
  mounted() {
    this.getState();
  },
  methods: {
    getState() {
      if (!this.stateId) {
        return;
      }
      find(this.stateId).then((response) => {
        this.state = response.data;
      });
    },
    saveOrUpdateState() {
      if (this.stateId) {
        this.updateState();
      } else {
        this.saveState();
      }
    },
    saveState() {
      create(this.state).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/estados");
      });
    },
    updateState() {
      update(this.stateId, this.state).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/estados");
      });
    },
  },
};
</script>
